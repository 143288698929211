<pnz-form id="pnz-help-desk-form" class="pnz-help-desk-form">
	<template v-if="registered">
		<h1 class="pnz-registered-icon-container">
			<pnz-svg-icon icon="check" />
		</h1>
		<br />
		<br />
		<h5 class="pnz-registered-text-container">
			{{message}}
		</h5>
	</template>
	<template v-else>
		<pnz-form-row v-if="!helpDeskReasonId">
			<pnz-form-group-with-error-block col-sm="12" field="helpDeskReasonId" type="select" :auto-focus="true" :input-options="helpDeskReasonOptions" :label="'HelpdeskReasonTitle' | localize" :form-model="model" />
		</pnz-form-row>
		<pnz-form-row>
			<pnz-form-group-with-error-block col-sm="12" field="email" :label="'referralInputEmail' | localize" :form-model="model" />
		</pnz-form-row>
		<pnz-form-row>
			<pnz-form-group-with-error-block col-sm="12" field="message" type="textarea" :label="'HelpdeskMessageTitle' | localize" :form-model="model" />
		</pnz-form-row>
		<pnz-form-row>
			<pnz-form-group-with-error-block col-sm="12" field="captcha" :label="'captchaCode' | localize" type="captcha" />
		</pnz-form-row>
		<pnz-form-row>
			<pnz-form-group :with-error="true">
				<pnz-button type="submit" class="btn btn-default pnz-btn pnz-spin-preloader-container" :is-loading="isLoading">{{ 'done' | localize }}</pnz-button>
			</pnz-form-group>
		</pnz-form-row>
		<pnz-form-row>
			<pnz-empty-row />
		</pnz-form-row>
	</template>
</pnz-form>
