import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import WithRender from './helpdesk.html';

import './helpdesk.scss';
import { BootstrapFormContainer } from 'ui-controls/forms/bootstrap-form-container';
import { ServerApiNamings } from 'server-api/server-api-namings';
import { NullAny } from 'utils/common/utils';

import helpdeskReasons from '#/helpdesk-reason.json';

class Model {
	public email!: string;
	public message!: string;
	public helpDeskReasonId: string = NullAny;
	public ticket!: string;
}

@WithRender
@Component
export class HelpDesk extends BootstrapFormContainer<Model> {
	@Prop({ type: String })
	public helpDeskReasonId!: string | null;

	public model: Model;
	public registered = false;
	public message: string | null = null;
	private dsHelpDeskControllerService: IDsHelpDeskControllerService;

	private lsTicketControllerService: ILsTicketControllerService;

	public get rules() {
		return {
			email: ['required'],
			message: ['required'],
			helpDeskReasonId: ['required'],
			ticket: []
		};
	}

	public get helpDeskReasonOptions() {
		return {
			options: helpdeskReasons.map(reason => {
				return { value: reason.id, text: this.localize(reason.nameKey as ILocalizationKey) };
			})
		};
	}

	constructor() {
		super();
		this.model = new Model();
		this.dsHelpDeskControllerService = this.get<IDsHelpDeskControllerService>(
			ServerApiNamings.dsHelpDeskControllerServiceName
		);
		this.lsTicketControllerService = this.get<ILsTicketControllerService>(
			ServerApiNamings.lsTicketControllerServiceName
		);
	}

	public created() {
		if (this.helpDeskReasonId) {
			this.model.helpDeskReasonId = this.helpDeskReasonId;
		}
	}

	protected processSubmit(destroyPromise: Promise<void>): Promise<any> {
		return this.lsTicketControllerService.ticket().then(ticketResponse => {
			const ticket = ticketResponse.data.ticket;
			this.model.ticket = ticket;
			return this.dsHelpDeskControllerService.ticket(this.model).then(response => {
				this.registered = true;
				this.message = response.message || null;
			});
		});
	}
}
